import React, { Component } from 'react';
import {
	ILanguageSelectorProps,
	ILanguageSelectorState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Dropdown from '@kurtosys/ksys-app-components/dist/components/base/Dropdown/Dropdown';

@inject('appStore', 'languageSelectorStore')
@observer
export class LanguageSelector extends Component<ILanguageSelectorProps, ILanguageSelectorState> {
	static configurationKey: 'languageSelector' = 'languageSelector';
	static styleKey: 'languageSelector' = 'languageSelector';
	constructor(props: ILanguageSelectorProps) {
		super(props);
	}
	render() {
		const { className, languageSelectorStore } = this.props;

		if (!languageSelectorStore || !languageSelectorStore.show) {
			return null;
		}

		const { dropdownProps } = languageSelectorStore;

		return (
			<Wrapper className={ className }>
				<Dropdown { ...dropdownProps } />
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(LanguageSelector, 'languageSelector');

