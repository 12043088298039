import React, { Component } from 'react';
import {
	IUserProfileButtonProps,
	IUserProfileButtonState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import UserProfileLabel from '../UserProfileLabel/UserProfileLabel';
import Tooltip from '@kurtosys/ksys-app-components/dist/components/base/Tooltip';
import { utils } from '@kurtosys/ksys-app-template';
import { Icon } from '@kurtosys/ksys-app-components/dist/components/base/Icon/Icon';
import StopImpersonatingButton from './styledComponents/StopImpersonatingButton';
import ProfileButton from './styledComponents/ProfileButton';
import { action, computed } from 'mobx';
import { KEYS } from '@kurtosys/ksys-app-components/dist/shared';

const ACCEPT_KEYS = [
	KEYS.ENTER,
	KEYS.SPACE,
	KEYS.SPACEBAR,
];

@inject('appStore', 'userProfileButtonStore')
@observer
export class UserProfileButton extends Component<IUserProfileButtonProps, IUserProfileButtonState> {
	static configurationKey: 'userProfileButton' = 'userProfileButton';
	static styleKey: 'userProfileButton' = 'userProfileButton';
	constructor(props: IUserProfileButtonProps) {
		super(props);
	}

	@computed
	get stopImpersonatingButton() {
		const { userProfileButtonStore, appStore } = this.props;

		if (!userProfileButtonStore || !appStore) {
			return null;
		}

		const { stopImpersonating } = appStore;
		const { tooltipPropsStopImpersonating, ariaLabelStopImpersonating } = userProfileButtonStore;
		const stopImpersonatingProps = {
			onClick: stopImpersonating,
			onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => this.handleKeyDown(e, stopImpersonating),
			tabIndex: 0,
			role: 'button',
			'aria-label': ariaLabelStopImpersonating,
		};

		if (!utils.typeChecks.isNullOrUndefined(tooltipPropsStopImpersonating)) {
			return (
				<Tooltip { ...tooltipPropsStopImpersonating }>
					<StopImpersonatingButton { ...stopImpersonatingProps }>
						<Icon onClick={ stopImpersonating } src="StopImpersonating.svg" />
					</StopImpersonatingButton>
				</Tooltip>
			);
		}

		return (
			<StopImpersonatingButton { ...stopImpersonatingProps }>
				<Icon onClick={ stopImpersonating } src="StopImpersonating.svg" />
			</StopImpersonatingButton>
		);

	}

	@action
	handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, action: () => void) => {
		if (ACCEPT_KEYS.includes(event.key as KEYS)) {
			const { appStore } = this.props;
			if (appStore) {
				action();
			}
		}
	}

	render() {
		const { className, userProfileButtonStore, appStore } = this.props;

		if (!userProfileButtonStore || !appStore) {
			return null;
		}
		const { goToProfile, isImpersonationActive } = appStore;
		const { tooltipProps, ariaLabelProfileButton } = userProfileButtonStore;

		const hasTooltip = !utils.typeChecks.isNullOrUndefined(tooltipProps);

		return (
			<Wrapper className={ className }>
				<ProfileButton
					onClick={ goToProfile }
					tabIndex={ 0 }
					onKeyDown={ (e: React.KeyboardEvent<HTMLDivElement>) => this.handleKeyDown(e, goToProfile) }
					role="button"
					aria-label={ ariaLabelProfileButton }
				>
					{ !hasTooltip && <UserProfileLabel /> }
					{ hasTooltip && <Tooltip { ...tooltipProps } ><UserProfileLabel /></Tooltip> }
				</ProfileButton>
				{ isImpersonationActive && this.stopImpersonatingButton }
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(UserProfileButton, 'userProfileButton');
