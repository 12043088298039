import { AppStoreBase } from './AppStoreBase';
import { IAppComponents } from '../models/IAppComponents';
import { computed, action, observable } from 'mobx';
import { StoreContext } from '../../../configuration/StoreContext';
import { Manifest } from '../../../configuration/Manifest';
import { TGetUserByTokenResponseBody } from '@kurtosys/ksys-api-client/dist/models/requests/auth/TGetUserByTokenResponseBody';
import { TDisplayType } from '../models/TDisplayType';
import { utils, helpers } from '@kurtosys/ksys-app-template';
import { IRedirect } from '../../../models/commonTypes';

/* [Component: appStoreComponentImport] */

export type TUserProperty = 'email' | 'userName' | 'name' | 'impersonatorUsername';

export class AppStore extends AppStoreBase {
	constructor(element: HTMLElement, url: string, storeContext: StoreContext, manifest: Manifest) {
		super(element, url, storeContext, manifest);
		this.initializeCheckLoggedInInterval();
	}

	customInitializeBefore = async () => {
		this.getUser();
		const bc = new BroadcastChannel('ksys-auth-app-cookie-updates');
		bc.addEventListener('message', async (event) => {
			if (event.origin === window.origin && event.data === 'ksys-auth-app-cookie-updated') {
				const existingUser = this.user ? { ...this.user } : undefined;
				// update the user based on token within cookie
				this.user = await this.storeContext.kurtosysApiStore.getUserByToken.execute();
				// only reload if the user has indeed changed.
				if (
					this.user && existingUser && (this.user.clientId !== existingUser.clientId ||
						this.user.userId !== existingUser.userId ||
						this.user.userName !== existingUser.userName)
				) {
					window.location.reload();
				}
			}
		}, false);
		if (this.storeContext.languageSelectorStore) {
			this.storeContext.languageSelectorStore.initialize();
		}
	}

	checkLoggedInInterval: NodeJS.Timeout | undefined;

	@computed
	get checkLoggedInIntervalTime(): number {
		return ((this.appComponentConfiguration && this.appComponentConfiguration.checkLoggedInIntervalTime) || 60) * 1000;
	}

	@action
	clearCheckLoggedInInterval = () => {
		if (this.checkLoggedInInterval) {
			clearInterval(this.checkLoggedInInterval);
		}
	}

	@action
	initializeCheckLoggedInInterval = () => {
		this.clearCheckLoggedInInterval();
		if (this.mustCheckLoggedIn) {
			this.checkLoggedInInterval = setInterval(() => {
				this.checkLoggedIn();
			}, this.checkLoggedInIntervalTime);
		}
		else {
			console.info('checkLoggedIn interval disabled');
		}
	}

	@computed
	get hasData(): boolean {
		return !utils.typeChecks.isNullOrUndefined(this.user);
	}

	@action
	contextsDidUpdateAfter = async () => {
		// TODO: Each Application should put custom logic here to handle changes to the data context
	}

	@computed
	get components(): IAppComponents {
		return {
			/* [Component: appStoreComponent] */
		};
	}

	getUserValue(key: TUserProperty): string {
		return this.user && this.user[key] || '';
	}

	@computed
	get displayType(): TDisplayType {
		return this.appComponentConfiguration && this.appComponentConfiguration.displayType || 'buttons';
	}

	@computed
	get homeRedirect(): IRedirect | undefined {
		return this.appComponentConfiguration && this.appComponentConfiguration.redirects && this.appComponentConfiguration.redirects.home;
	}

	@computed
	get logoutRedirect(): IRedirect | undefined {
		return this.appComponentConfiguration && this.appComponentConfiguration.redirects && this.appComponentConfiguration.redirects.logout;
	}

	@computed
	get profileRedirect(): IRedirect | undefined {
		return this.appComponentConfiguration && this.appComponentConfiguration.redirects && this.appComponentConfiguration.redirects.profile;
	}

	@computed
	get isImpersonationActive(): boolean {
		return !utils.typeChecks.isNullOrEmpty(this.getUserValue('impersonatorUsername'));
	}

	@action
	stopImpersonating = async () => {
		const { kurtosysApiStore } = this.storeContext;
		try {
			await kurtosysApiStore.stopImpersonating.execute();
			this.redirectTo(this.homeRedirect);
		}
		catch (ex) {
			console.warn(ex);
		}
	}

	@action
	logout = async () => {
		const { kurtosysApiStore } = this.storeContext;
		try {
			// When impersonating, we need to stop impersonating before logging out.
			if (this.isImpersonationActive) {
				await kurtosysApiStore.stopImpersonating.execute();
			}

			this.clearCheckLoggedInInterval();
			await kurtosysApiStore.logout.execute();
		}
		catch (ex) {
			console.warn(ex);
		}
		this.redirectTo(this.logoutRedirect);
	}

	@computed
	get mustCheckLoggedIn() {
		// Don't check logged in while editing in elementor
		return !/\/wp-admin\/post.php\?post=(\d*)\&action=elementor/.test(window.location.href);
	}

	@action
	checkLoggedIn = async () => {
		const { kurtosysApiStore } = this.storeContext;
		if (this.mustCheckLoggedIn) {
			try {
				const response = await kurtosysApiStore.checkLoggedIn.execute();
				if (!response || !response.loggedIn) {
					this.logout();
				}
			}
			catch (ex) {
				console.warn(ex);
				this.logout();
			}
		}
		else {
			console.log('skipped checkLoggedIn');
		}
	}

	@action
	goToProfile = async () => {
		this.redirectTo(this.profileRedirect);
	}

	@computed
	get redirectInputValues(): { [key: string]: any } {
		const redirectInputs = (this.appComponentConfiguration && this.appComponentConfiguration.redirectInputs) || {};
		return Object.keys(redirectInputs).reduce((acc, key) => {
			acc[key] = this.getQueryValue(redirectInputs[key]);
			return acc;
		}, {} as any);
	}

	public redirectTo(redirect?: IRedirect) {
		console.log({ redirect });
		if (!utils.typeChecks.isNullOrEmpty(redirect)) {
			const { defaultUrl, base } = redirect;
			const redirectHelper = new helpers.RedirectHelper(defaultUrl, base);
			const payload = {
				user: this.user,
				...this.redirectInputValues,
			};
			redirectHelper.go(redirect, payload);
		}
	}

	async customInitializeAfter() {

	}
}