import React, { Component } from 'react';
import {
	IDropdownMenuProps,
	IDropdownMenuState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import MenuItem from './styledComponents/MenuItem';
import ContextMenu from '@kurtosys/ksys-app-components/dist/components/base/ContextMenu/ContextMenu';
import UserProfileLabel from '../UserProfileLabel/UserProfileLabel';

@inject('appStore', 'dropdownMenuStore')
@observer
export class DropdownMenu extends Component<IDropdownMenuProps, IDropdownMenuState> {
	static configurationKey: 'dropdownMenu' = 'dropdownMenu';
	static styleKey: 'dropdownMenu' = 'dropdownMenu';
	constructor(props: IDropdownMenuProps) {
		super(props);
	}

	render() {
		const { className, dropdownMenuStore } = this.props;

		if (!dropdownMenuStore) {
			return null;
		}

		const { contextMenuItems } = dropdownMenuStore;
		const label = <UserProfileLabel />;

		return (
			<Wrapper className={ className }>
				<ContextMenu label={ label }>
					{
						contextMenuItems.map((item, index) => <MenuItem key={ `menuItem_${ index }` } onClick={ item.onClick }>{ item.label }</MenuItem>)
					}
				</ContextMenu>
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(DropdownMenu, 'dropdownMenu');


