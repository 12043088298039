import { computed, action } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IUserProfileLabelConfiguration } from '../models';
import { TIconType } from '../../../models/TIconType';
import { TIconPosition } from '../../../models/TIconPosition';
import { utils } from '@kurtosys/ksys-app-template';

export class UserProfileLabelStore extends StoreBase {
	static componentKey: 'userProfileLabel' = 'userProfileLabel';

	@computed
	get configuration(): IUserProfileLabelConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(UserProfileLabelStore.componentKey);
		}
	}

	@computed
	get labelValue(): string {
		return this.configuration && this.configuration.labelValue || '';
	}

	@computed
	get impersonatorUserName(): string {
		const { appStore } = this.storeContext;
		return appStore.getUserValue('impersonatorUsername');
	}

	@computed
	get labelText(): string {
		const { appStore } = this.storeContext;
		const label = this.configuration && this.configuration.label || 'email';
		if (label === 'value') {
			return this.labelValue;
		}
		return appStore.getUserValue(label);
	}

	@computed
	get impersonationLabelTexts(): { impersonator: string, impersonated: string } | undefined {
		const { appStore } = this.storeContext;
		if (appStore.isImpersonationActive) {
			return {
				impersonator: `${ appStore.getUserValue('impersonatorUsername') } as`,
				impersonated: this.labelText,
			};
		}
		return;
	}

	@computed
	get initials(): string {
		const { appStore } = this.storeContext;
		const name = appStore && appStore.user && appStore.user.name;
		if (!utils.typeChecks.isNullOrEmpty(name)) {
			const initials = name.split(' ').map(part => part[0]).join('');
			return initials;
		}
		return '';
	}

	@computed
	get hasIcon(): boolean {
		return this.iconType !== 'none';
	}

	@computed
	get iconType(): TIconType {
		return this.configuration && this.configuration.icon && this.configuration.icon.type || 'none';
	}

	@computed
	get iconPosition(): TIconPosition {
		return this.configuration && this.configuration.icon && this.configuration.icon.position || 'right';
	}

	@action
	async initialize(): Promise<void> {

	}
}