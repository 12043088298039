import React, { Component, Fragment } from 'react';
import {
	IUserProfileLabelProps,
	IUserProfileLabelState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Icon from './styledComponents/Icon';
import Initials from './styledComponents/Initials';
import Label from './styledComponents/Label';
import ImpersonatedUserLabel from './styledComponents/ImpersonatedUserLabel';
import ImpersonatorUserLabel from './styledComponents/ImpersonatorUserLabel';
import LabelWrapper from './styledComponents/LabelWrapper';
import IconWrapper from './styledComponents/IconWrapper';
import { computed } from 'mobx';

@inject('appStore', 'userProfileLabelStore')
@observer
export class UserProfileLabel extends Component<IUserProfileLabelProps, IUserProfileLabelState> {
	static configurationKey: 'userProfileLabel' = 'userProfileLabel';
	static styleKey: 'userProfileLabel' = 'userProfileLabel';
	constructor(props: IUserProfileLabelProps) {
		super(props);
	}

	@computed
	get icon() {
		const { userProfileLabelStore: userProfileLabelStore } = this.props;

		if (!userProfileLabelStore || !userProfileLabelStore.hasIcon) {
			return null;
		}

		const { iconType, initials } = userProfileLabelStore;

		return (
			<Fragment>
				{ iconType === 'initials' && <Initials>{ initials }</Initials> }
				{ iconType === 'icon' && <Icon src="UserProfile.svg" /> }
			</Fragment>
		);
	}

	render() {
		const { className, userProfileLabelStore, appStore } = this.props;

		if (!userProfileLabelStore || !appStore) {
			return null;
		}

		const { labelText, iconPosition, impersonationLabelTexts } = userProfileLabelStore;
		const { isImpersonationActive } = appStore;

		return (
			<Wrapper className={ className }>
				{ iconPosition === 'left' && <IconWrapper>{ this.icon }</IconWrapper> }
				<LabelWrapper>
					{
						!isImpersonationActive && <Label>{ labelText }</Label>
					}
					{
						isImpersonationActive && impersonationLabelTexts && (
							<Fragment>
								<ImpersonatorUserLabel>{ impersonationLabelTexts.impersonator }</ImpersonatorUserLabel>
								<ImpersonatedUserLabel>{ impersonationLabelTexts.impersonated }</ImpersonatedUserLabel>
							</Fragment>
						)
					}
				</LabelWrapper>
				{ iconPosition === 'right' && <IconWrapper>{ this.icon }</IconWrapper> }
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(UserProfileLabel, 'userProfileLabel');

