import { StoreBase } from '../../../common/StoreBase';
import { KurtosysApiStore } from './KurtosysApiStore.js';
import { IExecutionOptions, IQueryOptions } from '../../../models/commonTypes.js';
import { IFetchEntitiesOptions } from '@kurtosys/ksys-api-client/dist/models/query/IFetchEntitiesOptions.js';
import { IDocumentSearchRequest } from '@kurtosys/ksys-app-components/dist/models/commonTypes.js';
import { models } from '@kurtosys/ksys-app-template';
import { IQueryContextClient } from '@kurtosys/ksys-api-client/dist/models/query/IQueryContextClient';
import { QueryContext } from '@kurtosys/ksys-api-client/dist/query/QueryContext';

interface IFetchAndQueryOptions {
    query?: IQueryOptions;
    responseContext?: object;
    targetContext?: models.context.IBaseContextOptions;
    overridingExecutionOptions?: IExecutionOptions;
}

interface IKeyedQueries {
    key: string;
}

interface IDatasetsOptions extends IFetchAndQueryOptions, IKeyedQueries {
    staticInputs?: Record<string, any>;
}

interface IEntitiesOptions extends IFetchAndQueryOptions, IKeyedQueries {
    overrideFetchOptions?: IFetchEntitiesOptions;
    staticInputs?: Record<string, any>;
}

interface IDocumentsOptions extends IFetchAndQueryOptions {
    requestBody: IDocumentSearchRequest;
    existingDocumentsByClientCode: Set<string>;
}

type TOptions = IDatasetsOptions | IEntitiesOptions | IDocumentsOptions;
type TQueryContext = QueryContext;

export class FetchAndQueryStore extends StoreBase {
    private get appStore() {
        return this.storeContext.appStore;
    }

    private get apiStore(): KurtosysApiStore {
        return this.storeContext.kurtosysApiStore;
    }

    private get apiClient(): IQueryContextClient {
        return this.apiStore.client;
    }

    private get queryStore() {
        return this.storeContext.queryStore;
    }

    initialize(callbacks?: Record<string, EventListener>): Promise<void> {
        throw new Error('Method not implemented.');
    }

    private async queryFromApiResponse(
        options: TOptions,
        apiRequestCallback: (currentContext: models.context.IContextAndConfiguration, queryContext: TQueryContext) => Promise<any>,
    ): Promise<any | undefined> {
        if (!this.apiClient) {
            return;
        }
        const { query, responseContext, targetContext, overridingExecutionOptions } = options;
        const currentContext = this.queryStore.getContext(targetContext);
        if (currentContext.contextConfiguration) {
            const queryContext = new QueryContext(currentContext.contextConfiguration, this.appStore.dataContextSeed);
            const updatedContext: models.context.IContextAndConfiguration = await apiRequestCallback(currentContext, queryContext);
            this.queryStore.setContext({ ...targetContext, value: updatedContext });
            // only return a response if a query has been provided,
            // otherwise we will only update the targetContext based on the API Response received.
            if (query) {
                return this.getQueryValue(query, responseContext, overridingExecutionOptions);
            }
        }
    }

    /**
     * Execute the dataset request body as per data.context.datasets[{ datasetKey }].
     * The response will be loaded into context, thus the provided query for queryOptionsType: 'dataset' will work as usual
     *
     * @param options
     * @returns
     */
    async datasets(options: IDatasetsOptions) {
        return await this.queryFromApiResponse(options, async (currentContext, queryContext) => {
            const { key, overridingExecutionOptions } = options;
            if (currentContext?.contextConfiguration?.datasets) {
                const targetDataset = currentContext.contextConfiguration.datasets.find(
                    (dataset) => dataset.datasetKey === key,
                );
                if (targetDataset && currentContext.context?.datasets) {
                    currentContext.context.datasets[key] = await (
                        await queryContext.fetchDataset(
                            this.apiClient,
                            targetDataset,
                            overridingExecutionOptions || {},
                        ) as any // using any as the interface is incorrect and need updating in v2 and v4
                    )['value'];
                    return currentContext;
                }
            }
        });
    }

    /**
     * Execute the entities request body as per data.context.entities[{ id }].
     * The response will be loaded into context, thus the provided query for queryOptionsType: 'entities' will work as usual.
     *
     * @param options
     * @returns
     */
    async entities(options: IEntitiesOptions) {
        return await this.queryFromApiResponse(options, async (currentContext, queryContext) => {
            const { key, overridingExecutionOptions, overrideFetchOptions, staticInputs } = options;
            if (currentContext.context && currentContext.context.entities) {
                currentContext.context.entities[key] = await queryContext.fetchEntities(
                    this.apiClient,
                    key,
                    staticInputs || {},
                    currentContext.contextConfiguration || {},
                    overridingExecutionOptions || {},
                    overrideFetchOptions,
                );
                return currentContext;
            }
        });
    }

    /**
     * Execute a search documents request body as per provided configuration.
     * The response will be loaded into data.context.globalDocuments, data.context.documentEntities, data.context.documentJoinMap,
     * thus the provided query for queryOptionsType: 'documents' will work as usual.
     *
     * @param options
     * @returns
     */
    async documents(options: IDocumentsOptions) {
        return await this.queryFromApiResponse(options, async (currentContext, queryContext) => {
            const { requestBody, existingDocumentsByClientCode } = options;
            const documentResponse = await this.apiClient.documents.search.execute({ body: requestBody });
            currentContext.context = queryContext.orchestrateSearchDocumentResponse(
                documentResponse,
                currentContext.context || {},
            );
            return currentContext;
        });
    }
}
