import { computed, action } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IDropdownMenuConfiguration } from '../models';
import { TAction } from '../../../models/TAction';

export class DropdownMenuStore extends StoreBase {
	static componentKey: 'dropdownMenu' = 'dropdownMenu';

	@computed
	get configuration(): IDropdownMenuConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(DropdownMenuStore.componentKey);
		}
	}

	getOnClickForAction(action: TAction) {
		const { appStore } = this.storeContext;
		if (action === 'go-to-profile') {
			return appStore.goToProfile;
		}
		if (action === 'stop-impersonating') {
			return appStore.stopImpersonating;
		}
		if (action === 'logout') {
			return appStore.logout;
		}
	}

	@computed
	get contextMenuItems() {
		const { appStore } = this.storeContext;
		const items = this.configuration && this.configuration.items || [];
		return items.map((item: any) => {
			const { label, action } = item;
			if (action === 'stop-impersonating' && (appStore.getUserValue('impersonatorUsername') === '')) {
				return {
				};
			}
			return {
				label,
				onClick: this.getOnClickForAction(action),
			};
		});
	}

	@action
	async initialize(): Promise<void> {

	}
}