import { computed } from "mobx";
import { KurtosysApiStoreBase } from "./KurtosysApiStoreBase";
import { GetApplicationAppConfig } from "@kurtosys/ksys-api-client/dist/requests/root/GetApplicationAppConfig";
import { ListTranslations } from "@kurtosys/ksys-api-client/dist/requests/config/ListTranslations";
import { IQueryContextClient } from "@kurtosys/ksys-api-client/dist/models/query/IQueryContextClient";
import { ListCommentaries } from "@kurtosys/ksys-api-client/dist/requests/config/ListCommentaries";
import { ListDisclaimers } from "@kurtosys/ksys-api-client/dist/requests/config/ListDisclaimers";
import { SearchDocuments } from "@kurtosys/ksys-api-client/dist/requests/documents/SearchDocuments";
import { SearchEntity } from "@kurtosys/ksys-api-client/dist/requests/fund/SearchEntity";
import { GetTheme } from "@kurtosys/ksys-api-client/dist/requests/applicationManager/GetTheme";
import { GetApplicationClientConfiguration } from "@kurtosys/ksys-api-client/dist/requests/applicationManager/GetApplicationClientConfiguration";
import { RetrieveClientConfiguration } from "@kurtosys/ksys-api-client/dist/requests/config/RetrieveClientConfiguration";
import { SavedSearchEntity } from "@kurtosys/ksys-api-client/dist/requests/fund/SavedSearchEntity";
import { RetrieveDocument } from "@kurtosys/ksys-api-client/dist/requests/documents/RetrieveDocument";
import { Retrieve } from "@kurtosys/ksys-api-client/dist/requests/documents/Retrieve";
import { RetrieveDocumentZip } from "@kurtosys/ksys-api-client/dist/requests/documents/RetrieveDocumentZip";
import { GetFilters } from "@kurtosys/ksys-api-client/dist/requests/fund/GetFilters";
import { GetUserByToken } from '@kurtosys/ksys-api-client/dist/requests/auth/getUserByToken';
import { LogoutProxy } from '@kurtosys/ksys-api-client/dist/requests/auth/LogoutProxy';
import { StopImpersonating } from '@kurtosys/ksys-api-client/dist/requests/auth/StopImpersonating';
import { CheckLoggedIn } from '@kurtosys/ksys-api-client/dist/requests/auth/CheckLoggedIn';
import { RetrieveUserConfiguration } from '@kurtosys/ksys-api-client/dist/requests/config/RetrieveUserConfiguration';
import { UpsertUserConfiguration } from '@kurtosys/ksys-api-client/dist/requests/config/UpsertUserConfiguration';
import { models } from "@kurtosys/ksys-app-template";
import { Execute } from '@kurtosys/ksys-api-client/dist/requests/dataset/Execute';
import { ExecuteGet } from '@kurtosys/ksys-api-client/dist/requests/dataset/Execute';

export class KurtosysApiStore extends KurtosysApiStoreBase {
	@computed
	get client(): IQueryContextClient {
		const response: IQueryContextClient = {
			config: {
				clientConfiguration: {
					retrieve: this.createEndpoint(RetrieveClientConfiguration),
				},
			},
			commentaries: {
				list: this.createEndpoint(ListCommentaries),
			},
			disclaimers: {
				list: this.createEndpoint(ListDisclaimers),
			},
			documents: {
				search: this.createEndpoint(SearchDocuments),
				retrieve: this.createEndpoint(RetrieveDocument),
				retrieveByProperty: this.createEndpoint(Retrieve),
				retrieveZip: this.createEndpoint(RetrieveDocumentZip),
			},
			entity: {
				search: this.createEndpoint(SearchEntity),
				savedSearchEntity: this.createEndpoint(SavedSearchEntity),
			},
			datasets: {
				execute: this.createEndpoint(Execute),
				executeGet: this.createEndpoint(ExecuteGet),
			},
			options: {
				baseUrl: this.serviceUrl,
			},
		};
		return response;
	}

	@computed
	get getAppConfig(): GetApplicationAppConfig {
		return this.createEndpoint(GetApplicationAppConfig);
	}

	@computed
	get listTranslations(): ListTranslations {
		return this.createEndpoint(ListTranslations);
	}

	@computed
	get getTheme(): GetTheme {
		return this.createEndpoint(GetTheme);
	}

	@computed
	get getUserByToken(): GetUserByToken {
		return this.createEndpoint(GetUserByToken, {
			optionsOverride: {
				authenticationMode: models.api.common.AuthenticationMode.cookie,
				disableCachableRequests: true,
			},
		});
	}

	@computed
	get stopImpersonating(): StopImpersonating {
		return this.createEndpoint(StopImpersonating, {
			optionsOverride: {
				authenticationMode: models.api.common.AuthenticationMode.cookie,
			},
		});
	}

	@computed
	get logout(): LogoutProxy {
		return this.createEndpoint(LogoutProxy, {
			optionsOverride: {
				authenticationMode: models.api.common.AuthenticationMode.cookie,
			},
		});
	}

	@computed
	get checkLoggedIn(): CheckLoggedIn {
		return this.createEndpoint(CheckLoggedIn, {
			optionsOverride: {
				authenticationMode: models.api.common.AuthenticationMode.cookie,
			},
		});
	}

	@computed
	get retrieveUserConfiguration(): RetrieveUserConfiguration {
		return this.createEndpoint(RetrieveUserConfiguration, {
			optionsOverride: {
				authenticationMode: models.api.common.AuthenticationMode.cookie,
			},
		});
	}

	@computed
	get upsertUserConfiguration(): UpsertUserConfiguration {
		return this.createEndpoint(UpsertUserConfiguration, {
			optionsOverride: {
				authenticationMode: models.api.common.AuthenticationMode.cookie,
			},
		});
	}

	@computed
	get getApplicationClientConfiguration(): GetApplicationClientConfiguration {
		return this.createEndpoint(GetApplicationClientConfiguration);
	}

	@computed
	get getFilters(): GetFilters {
		return this.createEndpoint(GetFilters);
	}
}
