import { IDropdownItem } from '@kurtosys/ksys-app-components/dist/components/base/Dropdown/models/IDropdownItem';
import { IDropdownProps } from '@kurtosys/ksys-app-components/dist/components/base/Dropdown/models/IDropdownProps';
import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IRedirect } from '../../../models/commonTypes';
import { ILanguageSelectorConfiguration } from '../models';
import { helpers, utils } from '@kurtosys/ksys-app-template';

export class LanguageSelectorStore extends StoreBase {
	static componentKey: 'languageSelector' = 'languageSelector';
	static userConfigurationCode: 'user_context_language' = 'user_context_language';

	@observable selectedLanguage: string | undefined;

	@computed
	get configuration(): ILanguageSelectorConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(LanguageSelectorStore.componentKey);
		}
	}

	@computed
	get show(): boolean {
		return !!(this.configuration && this.configuration.show);
	}

	@computed
	get languages(): IDropdownItem[] {
		return (this.configuration && this.configuration.languages) || [];
	}

	@computed
	get redirect(): IRedirect | undefined {
		return this.configuration && this.configuration.redirect;
	}

	@computed
	get default(): string {
		return (this.configuration && this.configuration.default) || 'en';
	}

	@computed
	get iconPosition(): any {
		return this.configuration && this.configuration.iconPosition;
	}

	@computed
	get selectedItem(): IDropdownItem | undefined {
		return this.languages.find(lang => lang.value === this.selectedLanguage);
	}

	@computed
	get dropdownProps(): IDropdownProps {
		return {
			selectedItem: this.selectedItem,
			items: this.languages,
			iconPosition: this.iconPosition,
			onChange: this.changeLanguage,
		};
	}

	@action
	changeLanguage = async (item: IDropdownItem | IDropdownItem[]) => {
		if (!Array.isArray(item)) {
			this.selectedLanguage = item.value;
			await this.saveUserLanguage();
			this.performRedirect();
		}
	}

	@action
	async initialize(): Promise<void> {
		await this.fetchUserLanguage();
	}

	@action
	fetchUserLanguage = async () => {
		const { kurtosysApiStore } = this.storeContext;
		const body = {
			code: LanguageSelectorStore.userConfigurationCode,
		};
		const response: any = await kurtosysApiStore.retrieveUserConfiguration.execute({ body });
		const { language } = response;
		this.selectedLanguage = !utils.typeChecks.isNullOrEmpty(language) ? language : this.default;
	}

	@action
	saveUserLanguage = async () => {
		const { kurtosysApiStore } = this.storeContext;
		const body = {
			code: LanguageSelectorStore.userConfigurationCode,
			config: { language: this.selectedLanguage },
		};
		await kurtosysApiStore.upsertUserConfiguration.execute({ body });
	}

	@computed
	get redirectInputValues(): { [key: string]: any } {
		const redirectInputs = (this.configuration && this.configuration.redirectInputs) || {};
		return Object.keys(redirectInputs).reduce((acc, key) => {
			acc[key] = this.getQueryValue(redirectInputs[key]);
			return acc;
		}, {} as any);
	}

	performRedirect() {
		if (this.redirect) {
			const { defaultUrl, base } = this.redirect;
			const redirectHelper = new helpers.RedirectHelper(defaultUrl, base);
			const payload = {
				...this.redirectInputValues,
				language: this.selectedLanguage,
			};
			redirectHelper.go(this.redirect, payload);
		}
	}
}