import { computed, action } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IUserProfileButtonConfiguration } from '../models';
import { ITooltipProps } from '@kurtosys/ksys-app-components/dist/components/base/Tooltip/models';

export class UserProfileButtonStore extends StoreBase {
	static componentKey: 'userProfileButton' = 'userProfileButton';

	@computed
	get configuration(): IUserProfileButtonConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(UserProfileButtonStore.componentKey);
		}
	}

	@computed
	get tooltipProps(): ITooltipProps | undefined {
		return this.configuration && this.configuration.tooltipProps;
	}

	@computed
	get tooltipPropsStopImpersonating(): ITooltipProps | undefined {
		return this.configuration && this.configuration.tooltipPropsStopImpersonating;
	}

	@computed
	get ariaLabelProfileButton(): string | undefined {
		return this.tooltipProps && this.tooltipProps.content;
	}

	@computed
	get ariaLabelStopImpersonating(): string | undefined {
		return this.tooltipPropsStopImpersonating && this.tooltipPropsStopImpersonating.content;
	}

	@action
	async initialize(): Promise<void> {

	}
}