import React, { Component, Fragment } from 'react';
import {
	ILogoutButtonProps,
	ILogoutButtonState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Icon from './styledComponents/Icon';
import Tooltip from '@kurtosys/ksys-app-components/dist/components/base/Tooltip';
import { utils } from '@kurtosys/ksys-app-template';
import { action, computed } from 'mobx';
import { KEYS } from '@kurtosys/ksys-app-components/dist/shared';

const ACCEPT_KEYS = [
	KEYS.ENTER,
	KEYS.SPACE,
	KEYS.SPACEBAR,
];

@inject('appStore', 'logoutButtonStore')
@observer
export class LogoutButton extends Component<ILogoutButtonProps, ILogoutButtonState> {
	static configurationKey: 'logoutButton' = 'logoutButton';
	static styleKey: 'logoutButton' = 'logoutButton';
	constructor(props: ILogoutButtonProps) {
		super(props);
	}

	@computed
	get label() {
		const { logoutButtonStore, appStore } = this.props;

		if (!logoutButtonStore || !appStore) {
			return null;
		}

		const { label, showIcon, iconPosition } = logoutButtonStore;
		return (
			<Fragment>
				{ showIcon && iconPosition === 'left' && <Icon src="Logout.svg" /> }
				{ label }
				{ showIcon && iconPosition === 'right' && <Icon src="Logout.svg" /> }
			</Fragment>
		);
	}

	@action
	handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
		if (ACCEPT_KEYS.includes(event.key as KEYS)) {
			const { appStore } = this.props;
			if (appStore) {
				appStore.logout();
			}
		}
	}

	render() {
		const { className, logoutButtonStore, appStore } = this.props;

		if (!logoutButtonStore || !appStore) {
			return null;
		}

		const { tooltipProps, ariaLabelText } = logoutButtonStore;
		const { logout } = appStore;
		const hasTooltip = !utils.typeChecks.isNullOrUndefined(tooltipProps);

		return (
			<Wrapper
				className={ className }
				onClick={ logout }
				tabIndex={ 0 }
				onKeyDown={ (e: React.KeyboardEvent<HTMLElement>) => this.handleKeyDown(e) }
				role="button"
				aria-label={ ariaLabelText }
			>
				{ !hasTooltip && this.label }
				{ hasTooltip && <Tooltip { ...tooltipProps } >{ this.label }</Tooltip> }
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(LogoutButton, 'logoutButton');

